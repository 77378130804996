import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "ads"

class Ad{
    constructor(id, univers, period, isBot, sender, product, packaging, amount, remainingAmount, minQuality, price, insurance, isClosed){
        this.id = id
        this.univers = univers
        this.period = period
        this.isBot = isBot
        this.sender = sender
        this.product = product
        this.packaging = packaging
        this.amount = amount
        this.remainingAmount = remainingAmount
        this.minQuality = minQuality
        this.price = price
        this.insurance = insurance
        this.isClosed = isClosed
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let ads = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_ad = new Ad(doument.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
            ads.push(tmp_ad)
        })

        return ads
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let ad = new Ad(response.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)

        return ad
    }

    static async getByUnivers(universId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("univers", "==", universId))
        let response = await getDocs(documentQuery)

        let ads = []

        response.forEach(document => {
            let data = document.data()
            let tmp_ad = new Ad(document.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
            ads.push(tmp_ad)
        })

        return ads
    }

    static async getByPeriod(periodId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId))
        let response = await getDocs(documentQuery)

        let ads = []

        response.forEach(document => {
            let data = document.data()
            let tmp_ad = new Ad(document.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
            ads.push(tmp_ad)
        })

        return ads
    }

    static async getByUniversAndPeriod(universId, periodId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId), where("univers", "==", universId))
        let response = await getDocs(documentQuery)

        let ads = []

        response.forEach(document => {
            let data = document.data()
            let tmp_ad = new Ad(document.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
            ads.push(tmp_ad)
        })

        return ads
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let ads = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_ad = new Ad(document.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
                ads.push(tmp_ad)
            })
            
            if(callback != null){
                callback(ads)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let ad = new Ad(snapshot.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
            
            if(callback != null){
                callback(ad)
            }
        })
        return unsub
    }

    static async listenByUnivers(universId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("univers", "==", universId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let ads = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_ad =  new Ad(document.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
                ads.push(tmp_ad)
            })
            
            if(callback != null){
                callback(ads)
            }
        })
        return unsub
    } 

    static async listenByPeriod(periodId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let ads = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_ad =  new Ad(document.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
                ads.push(tmp_ad)
            })
            
            if(callback != null){
                callback(ads)
            }
        })
        return unsub
    }

    static async listenByUniversAndPeriod(universId, periodId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId), where("univers", "==", universId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let ads = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_ad =  new Ad(document.id, data.univers, data.period, data.isBot, data.sender, data.product, data.packaging, data.amount, data.remainingAmount, data.minQuality, data.price, data.insurance, data.isClosed)
                ads.push(tmp_ad)
            })
            
            if(callback != null){
                callback(ads)
            }
        })
        return unsub
    }  

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                univers : this.univers,
                period : this.period,
                isBot : this.isBot,
                sender : this.sender,
                product : this.product,
                packaging : this.packaging,
                amount : this.amount,
                remainingAmount : this.remainingAmount,
                minQuality : this.minQuality,
                price : this.price,
                insurance : this.insurance,
                isClosed : this.isClosed,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                univers : this.univers,
                period : this.period,
                isBot : this.isBot,
                sender : this.sender,
                product : this.product,
                packaging : this.packaging,
                amount : this.amount,
                remainingAmount : this.remainingAmount,
                minQuality : this.minQuality,
                price : this.price,
                insurance : this.insurance,
                isClosed : this.isClosed,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Ad