<template>
    <span v-if="profile.role != 'User' || (userSession && userSession.displayParameters && userSession.displayParameters.marketplace)">
        <v-card>
            <v-card-title primary-title class="justify-center">
                <h1 class="text-center mb-3">Marketplace</h1>
            </v-card-title>
            <v-card-text>
                <v-row class="d-flex flex-row align-center justify-center" v-if="profile.role != 'User'">
                    <v-col cols="12" md="4">
                        <v-select :items="sessions" item-text="name" return-object label="Sélectionnez une session" v-model="selectedSession" @change="onSessionSelected"></v-select>
                    </v-col>
                    <v-col cols="12" md="4" v-if="selectedSession">
                        <v-select :items="universes" item-text="name" return-object label="Sélectionnez un univers" v-model="selectedUnivers"></v-select>
                    </v-col>
                </v-row>
                <span v-if="selectedUnivers">
                    <v-tabs v-model="currentTab" color="primary" centered slider-color="primary">
                        <v-tab>
                            <span>Annonces</span>
                        </v-tab>
                        <v-tab>
                            <span>Contrats en cours</span>
                        </v-tab>
                        <v-tab>
                            <span>Contrats terminés</span>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="currentTab">
                        <v-tab-item class="py-3">
                            <AdList :user="user" :profile="profile" :selectedSession="selectedSession" :selectedUnivers="selectedUnivers" :selectedTeam="selectedTeam"></AdList>
                        </v-tab-item>
                        <v-tab-item class="py-3">
                            <ContractList :user="user" :profile="profile" :selectedSession="selectedSession" :selectedUnivers="selectedUnivers"></ContractList>
                        </v-tab-item>
                        <v-tab-item class="py-3">
                            <OldContractList :user="user" :profile="profile" :selectedSession="selectedSession" :selectedUnivers="selectedUnivers"></OldContractList>
                        </v-tab-item>
                    </v-tabs-items>
                </span>
            </v-card-text>
        </v-card>
    </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js"

import AdList from "@/components/marketplace/AdList.vue"
import ContractList from "@/components/marketplace/ContractList.vue"
import OldContractList from "@/components/marketplace/OldContractList.vue"

import Session from '@/classes/Session'
import Team from '@/classes/Team'
import Univers from '@/classes/Univers'

export default {
    name: "Marketplace",
    props: ["user", "profile", "userSession"],
    components: {
        AdList,
        ContractList,
        OldContractList,
    },
    setup() {
        return { 
            dataFormater,
        }
    }, 
    data() {
        return {
            unsub: [],
            sessions: [],
            universes: [],
            currentTab: null,
            selectedSession: null,
            selectedUnivers:null,
            selectedTeam: null,
        }
    },
    async created() {
        if(this.profile.role != "User"){
            this.unsub.push(Session.listenOnlySession((sessions) => {
                sessions.sort((a, b) => b.date - a.date)
                this.sessions = sessions
            }))
        }else{
            this.selectedTeam = await Team.getById(this.profile.team)
            let selectedUnivers = await Univers.getById(this.selectedTeam.univers)
            this.selectedSession = await Session.getById(selectedUnivers.session)
        }
    },
    methods: {
      async onSessionSelected(){
          this.universes = []
          this.selectedUnivers = null

          this.universes = await Univers.getBySession(this.selectedSession.id)
      }
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>
