<template>
    <span v-if="nextPeriod">
        <v-row class="d-flex flex-row align-center justify-center flex-wrap">
            <v-col class="pa-5" cols="12" v-if="!ads || ads.length <= 0">
                <H1 class="text-center">Aucune annonce n'est disponible pour le moment</H1>
            </v-col>
            <v-col cols="12" class="my-3 pa-3 d-flex justify-center" v-if="profile.role != 'User' || (selectedSession && selectedSession.mainParameters.marketplace.posting)">
                <v-btn color="primary" class="ml-3" outlined @click="openCreateAdDialog">
                    <v-icon>mdi-plus</v-icon>
                    Deposer une nouvelle annonce
                </v-btn>
            </v-col>
            <v-col class="pa-5" cols="12" sm="6" md="4" lg="3" v-for="ad in ads" v-if="profile.role != 'User' || ad.sender == profile.team || !ad.isClosed">
                <v-card>
                    <div class="pa-5 d-flex flex-column align-center justify-center">
                        <h2 class="pt-3 text-center">
                            <v-icon v-if="ad.isClosed" color="error" class="pr-1" small>mdi-lock</v-icon>
                            <span v-text="productsTable[ad.product].name"></span>
                            <v-icon v-if="ad.isClosed" color="error" class="pr-1" small>mdi-lock</v-icon>
                        </h2>
                        <h3 :class="'pb-3 text-center' + (profile.team == ad.sender ? ' primary--text' : '')" v-if="!ad.isBot" v-text="'('+(profile.role != 'User' ? (teamsTable[ad.sender].name+'-') :'')+teamsTable[ad.sender].publicName+')'"></h3>
                        <h3 :class="'pb-3 text-center' + (profile.role != 'User' ? ' primary--text' : '')" v-else v-text="'('+(profile.role != 'User' ? ('Admin-') :'')+ad.sender+')'"></h3>
                        <div class="mb-3 d-flex flex-row justify-center align-center">
                            <div class="ma-1">
                                <v-img contain height="auto" max-height="60px" max-width="60px" :src="require(`@/assets/images/icons/products/Product${productsTable[ad.product].icon}.svg`)"></v-img>
                            </div>
                            <div class="ma-1">
                            <h3 class="text-center"><v-icon>mdi-chevron-double-right</v-icon></h3>
                            </div>
                            <div class="ma-1">
                                <v-img contain height="auto" max-height="60px" max-width="60px" :src="require(`@/assets/images/icons/marketplacePackaging/${ad.packaging}.svg`)"></v-img>
                            </div>
                        </div>
                        <div style="width: 100%;">
                            <v-divider></v-divider>
                            <h4 class="pt-3 text-center" v-text="'RSE/Qualité min : ' + dataFormater.formatMoney(parseFloat(ad.minQuality)) + '/Unités'"></h4>
                            <h4 class="pt-3 text-center" v-text="'Quantité : '+parseInt(packagingTypes[ad.packaging].amount)+' / '+packagingTypes[ad.packaging].name"></h4>
                            <h4 class="py-3 text-center" v-text="'En stock : '+parseInt(ad.remainingAmount)+' '+packagingTypes[ad.packaging].name + '(s)'"></h4>
                            <v-divider></v-divider>
                            <h4 class="pt-3 text-center" v-show="selectedSession.mainParameters.marketplace.showInsurance">
                              <span v-text="'Assurance'"></span>
                              <span>
                                <v-tooltip bottom max-width="600" color="black">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="accent" icon x-small v-bind="attrs" v-on="on" >
                                      <v-icon small>mdi-help</v-icon>
                                    </v-btn>
                                  </template>
                                  <span v-text="'En cas d\'incapacité a honoré le contrat le vendeur seras tenu de versser le montant de l\'assurance a l\'acheteur (dans ce cas l\'acheteur ne seras pas tenu de regler le prix dudit contrat)'"></span>
                                </v-tooltip>
                              </span>
                              <span v-text="' : ' + dataFormater.formatMoney(parseFloat(ad.insurance)) + '/' +packagingTypes[ad.packaging].name"></span>
                            </h4>
                            <h4 class="py-3 text-center" v-text="'Prix : ' + dataFormater.formatMoney(parseFloat(ad.price)) + '/' +packagingTypes[ad.packaging].name"></h4>
                            <v-divider></v-divider>
                            <span class="pt-3 d-flex flex-column align-center justify-center">
                                <v-btn class="my-2" color="primary" v-if="profile.role == 'User' && ad.sender != profile.team && parseInt(ad.remainingAmount) > 0" outlined @click="openBuyAdDialog(ad)">Acheter</v-btn>
                                <v-btn class="my-2" color="primary" v-if="profile.role == 'User' && ad.sender != profile.team && parseInt(ad.remainingAmount) <= 0" disabled outlined>Out of stock</v-btn>
                                <v-btn class="my-2" color="accent" v-if="!ad.isClosed && (profile.role != 'User' || ad.sender == profile.team)" @click="closeAd(ad)" outlined>Fermer</v-btn>
                                <v-btn class="my-2" color="info" v-if="ad.isClosed && (profile.role != 'User' || ad.sender == profile.team)" @click="openAd(ad)" outlined>Activer</v-btn>
                                <v-btn class="my-2" color="error" v-if="profile.role != 'User'" outlined @click="deleteAd(ad)">Supprimer</v-btn>
                            </span>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="createAdDialog" scrollable persistent max-width="600px">
            <v-card>
                <v-card-title class="headline">Deposer une annonce</v-card-title>
                <v-card-text>
                    <v-switch label="Envoyer seulment a cette univers" v-model="onlyCurrentUnivers" v-show="profile.role != 'User'"></v-switch>
                    <v-text-field label="Entreprise" v-if="profile.role != 'User'" v-model="newAd.sender"></v-text-field>
                    <v-select label="Produit" :items="Object.values(productsTable)" item-text="name" return-object v-model="newAd.product"></v-select>
                    <v-select label="Conditionement" :items="availablePackagingTypes" item-text="name" return-object v-model="newAd.packaging"></v-select>
                    <v-text-field label="Quantité" v-model="newAd.amount" :disabled="newAd.packaging == undefined || newAd.packaging == null" :suffix="newAd.packaging ? (newAd.packaging.name + '(s) (' + parseInt(newAd.amount) * parseInt(newAd.packaging.amount)  + ' Unités)') : 'Veuillez sélectionner le type de conditionnement'"></v-text-field>
                    <v-text-field label="RSE/Qualité min" v-model="newAd.minQuality" suffix="€/Unité"></v-text-field>
                    <v-text-field label="Prix" :disabled="newAd.packaging == undefined || newAd.packaging == null" :suffix="(newAd.packaging ? '€/'+(newAd.packaging.name) : 'Veuillez sélectionner le type de conditionnement')" v-model="newAd.price"></v-text-field>
                    <v-text-field label="Assurance" v-show="selectedSession.mainParameters.marketplace.showInsurance" :disabled="newAd.packaging == undefined || newAd.packaging == null" :suffix="(newAd.packaging ? '€/'+(newAd.packaging.name) : 'Veuillez sélectionner le type de conditionnement')" v-model="newAd.insurance"></v-text-field>
                    <v-tooltip bottom max-width="600" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="accent" icon x-small v-bind="attrs" v-on="on" >
                          <v-icon small>mdi-help</v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'En cas d\'incapacité a honoré le contrat le vendeur seras tenu de versser le montant de l\'assurance a l\'acheteur (dans ce cas l\'acheteur ne seras pas tenu de regler le prix dudit contrat)'"></span>
                    </v-tooltip>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="accent" text @click="packagingInfoDialog = true">Infos sur les conditionnements</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="createAdDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="createAd">Valider</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="packagingInfoDialog" scrollable persistent max-width="600px">
            <v-card>
                <v-card-title class="headline">Infos sur les conditionnements</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" lg="4" v-for="packagingType in availablePackagingTypes">
                            <v-card outlined>
                                <h3 class="py-3 text-center" v-text="packagingType.name"></h3>
                                <v-divider></v-divider>
                                <v-img class="my-3" contain height="auto" max-height="100px" :src="require(`@/assets/images/icons/marketplacePackaging/${packagingType.value}.svg`)"></v-img>
                                <v-divider></v-divider>
                                <h4 class="pl-3 pt-3" v-text="'Quantité : ' + packagingType.amount + ' Unité(s)'"></h4>
                                <h4 class="pl-3" v-text="'Prix : ' + dataFormater.formatMoney(packagingType.price)"></h4>
                                <h4 class="pl-3 pb-3" v-text="'Distribution : ' + dataFormater.formatPercentage(packagingType.distribution)"></h4>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="packagingInfoDialog = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="buyAdDialog" scrollable persistent max-width="600px">
            <v-card>
                <v-card-title class="headline">Infos sur les conditionnements</v-card-title>
                <v-card-text v-if="buyAdData && buyAdData.ad">
                    <v-row>
                        <v-col cols="12">
                            <h2 class="pt-3 text-center" v-text="productsTable[buyAdData.ad.product].name"></h2>
                            <h3 :class="'pb-3 text-center' + (profile.team == buyAdData.ad.sender ? ' primary--text' : '')" v-if="!buyAdData.ad.isBot" v-text="'('+(profile.role != 'User' ? (teamsTable[buyAdData.ad.sender].name+'-') :'')+teamsTable[buyAdData.ad.sender].publicName+')'"></h3>
                            <h3 :class="'pb-3 text-center' + (profile.role != 'User' ? ' primary--text' : '')" v-else v-text="'('+(profile.role != 'User' ? ('Admin-') :'')+buyAdData.ad.sender+')'"></h3>
                            <div class="mb-3 d-flex flex-row justify-center align-center">
                                <div class="ma-1">
                                    <v-img contain height="auto" max-height="60px" max-width="60px" :src="require(`@/assets/images/icons/products/Product${productsTable[buyAdData.ad.product].icon}.svg`)"></v-img>
                                </div>
                                <div class="ma-1">
                                <h3 class="text-center"><v-icon>mdi-chevron-double-right</v-icon></h3>
                                </div>
                                <div class="ma-1">
                                    <v-img contain height="auto" max-height="60px" max-width="60px" :src="require(`@/assets/images/icons/marketplacePackaging/${buyAdData.ad.packaging}.svg`)"></v-img>
                                </div>
                            </div>
                            <div style="width: 100%;">
                                <v-divider></v-divider>
                                <h4 class="pt-3 text-center" v-text="'RSE/Qualité min : ' + dataFormater.formatMoney(parseFloat(buyAdData.ad.minQuality)) + '/Unités'"></h4>
                                <h4 class="pt-3 text-center" v-text="'Quantité : '+parseInt(packagingTypes[buyAdData.ad.packaging].amount)+' / '+packagingTypes[buyAdData.ad.packaging].name"></h4>
                                <h4 class="py-3 text-center" v-text="'En stock : '+parseInt(buyAdData.ad.remainingAmount)+' '+packagingTypes[buyAdData.ad.packaging].name + '(s)'"></h4>
                                <v-divider></v-divider>
                                <h4 class="pt-3 text-center" v-text="'Prix : ' + dataFormater.formatMoney(parseFloat(buyAdData.ad.price)) + '/' +packagingTypes[buyAdData.ad.packaging].name"></h4>
                                <h4 class="py-3 text-center" v-show="selectedSession.mainParameters.marketplace.showInsurance" v-text="'Assurance : ' + dataFormater.formatMoney(parseFloat(buyAdData.ad.insurance)) + '/' +packagingTypes[buyAdData.ad.packaging].name"></h4>
                                <v-divider></v-divider>
                                <span class="pt-3 d-flex flex-column align-center justify-center">
                                    <v-text-field label="Quantité" v-model="buyAdData.amount" :suffix="packagingTypes[buyAdData.ad.packaging].name + '(s)'"></v-text-field>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="buyAdDialog = false">Fermer</v-btn>
                    <v-btn color="primary" text @click="buyAd()">Acheter</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
    <span v-else>
        <v-row class="d-flex flex-row align-center justify-center">
            <v-col class="pa-5" cols="12">
                <H1 class="text-center">Le marketplace est fermé pour le moment</H1>
            </v-col>
        </v-row>
    </span>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import dataFormater from "@/assets/functions/dataFormater.js"

import Period from '@/classes/Period'
import Univers from '@/classes/Univers'
import Team from '@/classes/Team'
import Ad from '@/classes/Ad'
import Contract from '@/classes/Contract'

export default {
    name: "AdList",
    props: ["user", "profile", "selectedSession", "selectedUnivers", "selectedTeam"],
    setup() {
        return { 
            dataFormater,
        }
    },
    data() {
        return {
            nextPeriod: null,
            unsub: [],
            ads: [],
            createAdDialog: false,
            packagingInfoDialog: false,
            buyAdDialog: false,
            packagingTypes: {
                box : {
                    value : "box",
                    name : "Carton",
                    amount : null,
                    price : null,
                    distribution : null,
                    enabled : false,
                },
                pallet : {
                    value : "pallet",
                    name : "Palette",
                    amount : null,
                    price : null,
                    distribution : null,
                    enabled : false,
                },
                container : {
                    value : "container",
                    name : "Conteneur",
                    amount : null,
                    price : null,
                    distribution : null,
                    enabled : false,
                },
            },
            onlyCurrentUnivers : true,
            newAd : {
                isBot : false,
                sender : null,
                product : null,
                packaging : null,
                amount : 0,
                remainingAmount : 0,
                minQuality : 0,
                price : 0,
                insurance : 0,
            },
            buyAdData : {
                ad : null,
                amount : 0,
            },
            productsTable : {},
            teamsTable : {},
        }
    },
    computed: {
        availablePackagingTypes(){
            let availablePackagingTypes = Object.values(this.packagingTypes).filter(packagingType => packagingType.enabled)
            return availablePackagingTypes
        }
    },
    watch: {
        selectedSession : {
            async handler(val){
                if(val){
                    for(let packageKey in this.packagingTypes){
                        this.packagingTypes[packageKey].amount = this.selectedSession.mainParameters.marketplace[packageKey].amount
                        this.packagingTypes[packageKey].price = this.selectedSession.mainParameters.marketplace[packageKey].price
                        this.packagingTypes[packageKey].distribution = this.selectedSession.mainParameters.marketplace[packageKey].distribution
                        this.packagingTypes[packageKey].enabled = this.selectedSession.mainParameters.marketplace[packageKey].enabled
                    }
                    this.productsTable = this.selectedSession.productsConfig

                    let tmp_teamsTable = {}
                    let universes = await Univers.getBySession(this.selectedSession.id)
                    for(let universe of universes){
                        let teams = await Team.getByUnivers(universe.id)
                        for(let team of teams){
                            tmp_teamsTable[team.id] = team
                        }
                    }
                    this.teamsTable = tmp_teamsTable
                }
            },
            deep : true,
            immediate : true
        },
        selectedUnivers : {
            async handler(val){
                if(val){
                    let periods = await Period.getBySession(this.selectedSession.id)
                    periods = periods.sort((a, b) => a.end - b.end)
                    
                    for(let period of periods){
                        if(period.end > Date.now()){
                            this.nextPeriod = period
                            break
                        }
                    }
                    if(this.nextPeriod){
                        this.unsub.push(
                            Ad.listenByUniversAndPeriod(this.selectedUnivers.id, this.nextPeriod.id, (ads) => {
                                this.ads = ads
                            })
                        )
                    }
                }
            },
            deep : true,
            immediate : true
        },
    },
    methods: {
        openCreateAdDialog(){
            this.newAd = {
                isBot : this.profile.role != 'User',
                sender : this.profile.role == 'User' ? this.selectedTeam : null,
                product : null,
                packaging : null,
                amount : 0,
                remainingAmount : 0,
                minQuality : 0,
                price : 0,
                insurance : 0,
            }

            if(this.profile.role != 'User'){
              this.onlyCurrentUnivers = false
            }else{
              this.onlyCurrentUnivers = true
            }

            this.createAdDialog = true
        },
        openBuyAdDialog(ad){
            this.buyAdData = {
                ad : ad,
                amount : 1,
            },
            this.buyAdDialog = true
        },
        async createAd(){

            let sender = this.newAd.sender
            if(!this.newAd.isBot){
                sender = this.newAd.sender.id
            }

            if(this.profile.role == 'User' || this.onlyCurrentUnivers){
              let newAd = new Ad(null, this.selectedUnivers.id, this.nextPeriod.id, this.newAd.isBot, sender, this.newAd.product.uuid, this.newAd.packaging.value, this.newAd.amount, this.newAd.amount, this.newAd.minQuality, this.newAd.price, this.newAd.insurance, false)
              await newAd.save()
            }else{
              let universes = await Univers.getBySession(this.selectedSession.id)
              for(let universe of universes){
                let newAd = new Ad(null, universe.id, this.nextPeriod.id, this.newAd.isBot, sender, this.newAd.product.uuid, this.newAd.packaging.value, this.newAd.amount, this.newAd.amount, this.newAd.minQuality, this.newAd.price, this.newAd.insurance, false)
                await newAd.save()
              }
            }

            this.createAdDialog = false
        },
        async buyAd(){
            if(parseInt(this.buyAdData.ad.remainingAmount) >= parseInt(this.buyAdData.amount)){
                let newContract = new Contract(null, this.buyAdData.ad.session, this.buyAdData.ad.period, this.buyAdData.ad.id, this.buyAdData.ad.sender, this.profile.team, this.buyAdData.amount, 'contracted')
                await newContract.save()
                this.buyAdData.ad.remainingAmount = parseInt(this.buyAdData.ad.remainingAmount) - parseInt(this.buyAdData.amount)
                await this.buyAdData.ad.save()
                Swal.fire({
                    title: 'Achat confirmé',
                    text: "Votre achat a bien été effectué.",
                    icon: 'success',
                }).then((result) => {
                    this.buyAdDialog = false
                })
            }else{
                Swal.fire({
                    title: 'Achat impossible',
                    text: "Vous ne pouvez pas acheter une quantité supérieure à celle en stock dans l'annonce.",
                    icon: 'error',
                })
            }
        },
        async closeAd(ad){
            ad.isClosed = true
            await ad.save()
        },
        async openAd(ad){
            ad.isClosed = false
            await ad.save()
        },
        async deleteAd(ad){
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer cette annonce et tout ses contrats ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let contracts = await Contract.getByAd(ad.id)
                    if(contracts && contracts.length > 0){
                        for(let contract of contracts){
                            await contract.delete()
                        }
                    }
                    await ad.delete()
                }
            })
        },
    },
}
</script>
