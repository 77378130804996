import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "contracts"

class Contract{
    constructor(id, univers, period, ad, sender, receiver, amount, status){
        this.id = id
        this.univers = univers
        this.period = period
        this.ad = ad
        this.sender = sender
        this.receiver = receiver
        this.amount = amount
        this.status = status
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let contracts = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_contract = new Contract(doument.id, data.univers, data.period, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
            contracts.push(tmp_contract)
        })

        return contracts
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let contract = new Contract(response.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)

        return contract
    }

    static async getByAd(adId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("ad", "==", adId))
        let response = await getDocs(documentQuery)

        let contracts = []

        response.forEach(document => {
            let data = document.data()
            let tmp_contract = new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
            contracts.push(tmp_contract)
        })

        return contracts
    }

    static async getBySender(senderId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("sender", "==", senderId))
        let response = await getDocs(documentQuery)

        let contracts = []

        response.forEach(document => {
            let data = document.data()
            let tmp_contract = new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
            contracts.push(tmp_contract)
        })

        return contracts
    }

    static async getByReceiver(receiverId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("receiver", "==", receiverId))
        let response = await getDocs(documentQuery)

        let contracts = []

        response.forEach(document => {
            let data = document.data()
            let tmp_contract = new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
            contracts.push(tmp_contract)
        })

        return contracts
    }

    static async getByUnivers(universId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("univers", "==", universId))
        let response = await getDocs(documentQuery)

        let ads = []

        response.forEach(document => {
            let data = document.data()
            let tmp_ad = new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
            ads.push(tmp_ad)
        })

        return ads
    }

    static async getByPeriod(periodId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId))
        let response = await getDocs(documentQuery)

        let ads = []

        response.forEach(document => {
            let data = document.data()
            let tmp_ad = new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
            ads.push(tmp_ad)
        })

        return ads
    }

    static async getByUniversAndPeriod(universId, periodId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId), where("univers", "==", universId))
        let response = await getDocs(documentQuery)

        let ads = []

        response.forEach(document => {
            let data = document.data()
            let tmp_ad = new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
            ads.push(tmp_ad)
        })

        return ads
    }
    

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let contracts = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_contract = new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
                contracts.push(tmp_contract)
            })
            
            if(callback != null){
                callback(contracts)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let contract = new Contract(snapshot.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
            
            if(callback != null){
                callback(contract)
            }
        })
        return unsub
    }

    static async listenByAd(adId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("ad", "==", adId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let contracts = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_contract =  new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
                contracts.push(tmp_contract)
            })
            
            if(callback != null){
                callback(contracts)
            }
        })
        return unsub
    } 

    static async listenBySender(senderId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("sender", "==", senderId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let contracts = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_contract =  new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
                contracts.push(tmp_contract)
            })
            
            if(callback != null){
                callback(contracts)
            }
        })
        return unsub
    } 

    static async listenByReceiver(receiverId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("receiver", "==", receiverId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let contracts = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_contract =  new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
                contracts.push(tmp_contract)
            })
            
            if(callback != null){
                callback(contracts)
            }
        })
        return unsub
    } 

    static async listenByUnivers(universId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("univers", "==", universId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let ads = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_ad =  new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
                ads.push(tmp_ad)
            })
            
            if(callback != null){
                callback(ads)
            }
        })
        return unsub
    } 

    static async listenByPeriod(periodId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let ads = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_ad =  new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
                ads.push(tmp_ad)
            })
            
            if(callback != null){
                callback(ads)
            }
        })
        return unsub
    }

    static async listenByUniversAndPeriod(universId, periodId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId), where("univers", "==", universId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let ads = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_ad =  new Contract(document.id, data.univers, data.period, data.ad, data.sender, data.receiver, data.amount, data.status)
                ads.push(tmp_ad)
            })
            
            if(callback != null){
                callback(ads)
            }
        })
        return unsub
    }  

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                univers : this.univers,
                period : this.period,
                ad : this.ad,
                sender : this.sender,
                receiver : this.receiver,
                amount : this.amount,
                status : this.status
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                univers : this.univers,
                period : this.period,
                ad : this.ad,
                sender : this.sender,
                receiver : this.receiver,
                amount : this.amount,
                status : this.status
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Contract