<template>
    <span v-if="nextPeriod">
        <v-row class="d-flex flex-row align-center justify-center flex-wrap">
            <v-col class="pa-5" cols="12" v-if="!contracts || contracts.length <= 0">
                <H1 class="text-center">Aucun contrat n'est disponible pour le moment</H1>
            </v-col>
            <v-col class="pa-5" cols="12" sm="6" md="4" lg="3" v-for="contract in contracts" v-if="profile.role != 'User' || contract.sender == profile.team || contract.receiver == profile.team">
                <v-card>
                    <div class="pa-5 d-flex flex-column align-center justify-center">
                        <h2 class="pt-3 text-center">
                            <span v-text="productsTable[adsTable[contract.ad].product].name"></span>
                        </h2>
                        <h3 :class="'pb-3 text-center' + (profile.team == adsTable[contract.ad].sender ? ' primary--text' : '')" v-if="!adsTable[contract.ad].isBot" v-text="'('+(profile.role != 'User' ? (teamsTable[adsTable[contract.ad].sender].name+'-') :'')+teamsTable[adsTable[contract.ad].sender].publicName+')'"></h3>
                        <h3 :class="'pb-3 text-center' + (profile.role != 'User' ? ' primary--text' : '')" v-else v-text="'('+(profile.role != 'User' ? ('Admin-') :'')+adsTable[contract.ad].sender+')'"></h3>
                        <div class="mb-3 d-flex flex-row justify-center align-center">
                            <div class="ma-1">
                                <v-img contain height="auto" max-height="60px" max-width="60px" :src="require(`@/assets/images/icons/products/Product${productsTable[adsTable[contract.ad].product].icon}.svg`)"></v-img>
                            </div>
                            <div class="ma-1">
                            <h3 class="text-center"><v-icon>mdi-chevron-double-right</v-icon></h3>
                            </div>
                            <div class="ma-1">
                                <v-img contain height="auto" max-height="60px" max-width="60px" :src="require(`@/assets/images/icons/marketplacePackaging/${adsTable[contract.ad].packaging}.svg`)"></v-img>
                            </div>
                        </div>
                        <div style="width: 100%;">
                            <v-divider></v-divider>
                            <h3 class="pt-3 text-center">
                                <h3 :class="'pt-3 text-center' + (profile.team == contract.sender ? ' primary--text' : '')" v-if="!adsTable[contract.ad].isBot" v-text="(profile.role != 'User' ? (teamsTable[contract.sender].name+'-') :'')+teamsTable[contract.sender].publicName"></h3>
                                <h3 class="pt-3 text-center" v-else v-text="contract.sender"></h3>
                                <v-icon>mdi-chevron-double-down</v-icon>
                                <h3 :class="'pb-3 text-center' + (profile.team == contract.receiver ? ' primary--text' : '')" v-text="(profile.role != 'User' ? (teamsTable[contract.receiver].name+'-') :'')+teamsTable[contract.receiver].publicName"></h3>
                            </h3>
                            <v-divider></v-divider>
                            <h4 class="pt-3 text-center" v-text="'RSEQualité min : ' + dataFormater.formatMoney(parseFloat(adsTable[contract.ad].minQuality)) + '/Unités'"></h4>
                            <h4 class="pt-3 text-center" v-text="'Quantité : '+parseInt(packagingTypes[adsTable[contract.ad].packaging].amount)+' / '+packagingTypes[adsTable[contract.ad].packaging].name"></h4>
                            <h4 class="py-3 text-center" v-text="'Quantité commandée : '+parseInt(contract.amount)+' '+packagingTypes[adsTable[contract.ad].packaging].name + '(s)'"></h4>
                            <v-divider></v-divider>
                            <h4 class="pt-3 text-center" v-text="'Prix : ' + dataFormater.formatMoney(parseFloat(adsTable[contract.ad].price)) + '/' +packagingTypes[adsTable[contract.ad].packaging].name"></h4>
                            <h4 class="py-3 text-center" v-show="selectedSession.mainParameters.marketplace.showInsurance" v-text="'Assurance : ' + dataFormater.formatMoney(parseFloat(adsTable[contract.ad].insurance)) + '/' +packagingTypes[adsTable[contract.ad].packaging].name"></h4>
                            <v-divider v-if="profile.role != 'User'"></v-divider>
                            <span class="pt-3 d-flex flex-column align-center justify-center" v-if="profile.role != 'User'">
                                <v-btn class="my-2" color="error" outlined @click="deleteContract(contract)">Supprimer</v-btn>
                            </span>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </span>
    <span v-else>
        <v-row class="d-flex flex-row align-center justify-center">
            <v-col class="pa-5" cols="12">
                <H1 class="text-center">Le marketplace est fermé pour le moment</H1>
            </v-col>
        </v-row>
    </span>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import dataFormater from "@/assets/functions/dataFormater.js"

import Period from '@/classes/Period'
import Univers from '@/classes/Univers'
import Team from '@/classes/Team'
import Ad from '@/classes/Ad'
import Contract from '@/classes/Contract'

export default {
    name: "ContractList",
    props: ["user", "profile", "selectedSession", "selectedUnivers", "selectedTeam"],
    setup() {
        return { 
            dataFormater,
        }
    },
    data() {
        return {
            nextPeriod: null,
            unsub: [],
            contracts: [],
            packagingTypes: {
                box : {
                    value : "box",
                    name : "Carton",
                    amount : null,
                    price : null,
                    distribution : null,
                    enabled : false,
                },
                pallet : {
                    value : "pallet",
                    name : "Palette",
                    amount : null,
                    price : null,
                    distribution : null,
                    enabled : false,
                },
                container : {
                    value : "container",
                    name : "Conteneur",
                    amount : null,
                    price : null,
                    distribution : null,
                    enabled : false,
                },
            },
            productsTable : {},
            teamsTable : {},
            adsTable : {},
        }
    },
    watch: {
        selectedSession : {
            async handler(val){
                if(val){
                    for(let packageKey in this.packagingTypes){
                        this.packagingTypes[packageKey].amount = this.selectedSession.mainParameters.marketplace[packageKey].amount
                        this.packagingTypes[packageKey].price = this.selectedSession.mainParameters.marketplace[packageKey].price
                        this.packagingTypes[packageKey].distribution = this.selectedSession.mainParameters.marketplace[packageKey].distribution
                        this.packagingTypes[packageKey].enabled = this.selectedSession.mainParameters.marketplace[packageKey].enabled
                    }
                    this.productsTable = this.selectedSession.productsConfig

                    let tmp_teamsTable = {}
                    let universes = await Univers.getBySession(this.selectedSession.id)
                    for(let universe of universes){
                        let teams = await Team.getByUnivers(universe.id)
                        for(let team of teams){
                            tmp_teamsTable[team.id] = team
                        }
                    }
                }
            },
            deep : true,
            immediate : true
        },
        selectedSession : {
            async handler(val){
                if(val){
                    let periods = await Period.getBySession(this.selectedSession.id)
                    periods = periods.sort((a, b) => a.end - b.end)
                    
                    for(let period of periods){
                        if(period.end > Date.now()){
                            this.nextPeriod = period
                            break
                        }
                    }

                    if(this.nextPeriod){
                        this.unsub.push(
                            Ad.listenByUniversAndPeriod(this.selectedUnivers.id, this.nextPeriod.id, (ads) => {
                                this.adsTable = {}
                                for(let ad of ads){
                                    this.adsTable[ad.id] = ad
                                }
                            })
                        )

                        this.unsub.push(
                            Contract.listenByUniversAndPeriod(this.selectedUnivers.id, this.nextPeriod.id, (contracts) => {
                                let tmp_contracts = []
                                for(let contract of contracts){
                                    if(contract.status == "contracted"){
                                        tmp_contracts.push(contract)
                                    }
                                }
                                this.contracts = tmp_contracts
                            })
                        )
                    }
                }
            },
            deep : true,
            immediate : true
        },
    },
    methods: {
        async deleteContract(contract){
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer ce contrat ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.adsTable[contract.ad].remainingAmount = parseInt(this.adsTable[contract.ad].remainingAmount) + parseInt(contract.amount)
                    await this.adsTable[contract.ad].save()
                    await contract.delete()
                }
            })
        },
    },
}
</script>
